:root ._dsp-flex{display:flex;}
:root ._ai-stretch{align-items:stretch;}
:root ._fd-column{flex-direction:column;}
:root ._fb-auto{flex-basis:auto;}
:root ._bxs-border-box{box-sizing:border-box;}
:root ._pos-relative{position:relative;}
:root ._mih-0px{min-height:0px;}
:root ._miw-0px{min-width:0px;}
:root ._fs-0{flex-shrink:0;}
:root ._pr-t-space-4{padding-right:var(--t-space-4);}
:root ._pl-t-space-4{padding-left:var(--t-space-4);}
:root ._pt-t-space-8{padding-top:var(--t-space-8);}
:root ._pb-t-space-8{padding-bottom:var(--t-space-8);}
@media (min-width: 801px){:root:root:root:root:root:root:root:root:root ._pr-_gtXs_t-space-8{padding-right:var(--t-space-8);}}
@media (min-width: 801px){:root:root:root:root:root:root:root:root:root ._pl-_gtXs_t-space-8{padding-left:var(--t-space-8);}}
@media (min-width: 801px){:root:root:root:root:root:root:root:root:root ._pt-_gtXs_t-space-8{padding-top:var(--t-space-8);}}
@media (min-width: 801px){:root:root:root:root:root:root:root:root:root ._pb-_gtXs_t-space-8{padding-bottom:var(--t-space-8);}}
@media (min-width: 801px){:root:root:root:root:root:root:root:root:root ._fg-_gtXs_1{flex-grow:1;}}
@media (min-width: 801px){:root:root:root:root:root:root:root:root:root ._fs-_gtXs_1{flex-shrink:1;}}
@media (min-width: 801px){:root:root:root:root:root:root:root:root:root ._fb-_gtXs_auto{flex-basis:auto;}}
@media (min-width: 801px){:root:root:root:root:root:root:root:root:root ._miw-_gtXs_6537{min-width:65%;}}
@media (min-width: 1025px){:root:root:root:root:root:root:root:root:root:root ._pr-_gtSm_t-space-12{padding-right:var(--t-space-12);}}
@media (min-width: 1025px){:root:root:root:root:root:root:root:root:root:root ._pl-_gtSm_t-space-12{padding-left:var(--t-space-12);}}
@media (min-width: 1025px){:root:root:root:root:root:root:root:root:root:root ._pt-_gtSm_t-space-12{padding-top:var(--t-space-12);}}
@media (min-width: 1025px){:root:root:root:root:root:root:root:root:root:root ._pb-_gtSm_t-space-12{padding-bottom:var(--t-space-12);}}
@media (min-width: 1025px){:root:root:root:root:root:root:root:root:root:root ._miw-_gtSm_4537{min-width:45%;}}
:root ._dsp-inline{display:inline;}
:root ._ww-break-word{word-wrap:break-word;}
:root ._whiteSpace-pre-wrap{white-space:pre-wrap;}
:root ._mt-t-space-2{margin-top:var(--t-space-2);}
:root ._mr-0px{margin-right:0px;}
:root ._mb-0px{margin-bottom:0px;}
:root ._ml-0px{margin-left:0px;}
:root ._col-onSurface--1130999482{color:var(--onSurface--neutral--default);}
:root ._ff-f-family{font-family:var(--f-family);}
:root ._fow-f-weight-4{font-weight:var(--f-weight-4);}
:root ._ls-f-letterSpa779312659{letter-spacing:var(--f-letterSpacing-16);}
:root ._fos-f-size-40{font-size:var(--f-size-40);}
:root ._lh-f-lineHeigh3500669{line-height:var(--f-lineHeight-48);}
:root ._ussel-auto{user-select:auto;-webkit-user-select:auto;}
@media (min-width: 801px){:root:root:root:root:root:root:root:root:root ._fow-_gtXs_t-space-4{font-weight:var(--t-space-4);}}
:root ._mt-t-space-3{margin-top:var(--t-space-3);}
:root ._fow-normal{font-weight:normal;}
:root ._fos-f-size-24{font-size:var(--f-size-24);}
:root ._lh-f-lineHeigh3500632{line-height:var(--f-lineHeight-32);}
:root ._ox-hidden{overflow-x:hidden;}
:root ._oy-hidden{overflow-y:hidden;}
:root ._w-10037{width:100%;}
:root ._h-250px{height:250px;}
:root ._mr-auto{margin-right:auto;}
:root ._ml-auto{margin-left:auto;}
@media (min-width: 801px){:root:root:root:root:root:root:root:root:root ._h-_gtXs_400px{height:400px;}}
@media (min-width: 801px){:root:root:root:root:root:root:root:root:root ._w-_gtXs_600px{width:600px;}}
@media (min-width: 1025px){:root:root:root:root:root:root:root:root:root:root ._h-_gtSm_680px{height:680px;}}
@media (min-width: 1025px){:root:root:root:root:root:root:root:root:root:root ._w-_gtSm_800px{width:800px;}}
:root ._h-10037{height:100%;}
@media (min-width: 1025px){:root:root:root:root:root:root:root:root:root:root ._w-_gtSm_620px{width:620px;}}